import React from "react";
import belt from "../img/beltsample.png";
import belt2 from "../img/beltsample2.png";
import right from "../img/right.svg";
import left from "../img/left.svg";

export default class Productstote extends React.Component {
  state = {
    images: this.props.images,
    idx: 0,
    multiple: true,
  };

  handleNext = () => {
    if (this.state.idx === this.state.images.length - 1) {
      this.setState({
        idx: 0,
      });
    } else {
      this.setState({
        idx: this.state.idx + 1,
      });
    }
  };

  handlePrev = () => {
    if (this.state.idx === 0) {
      this.setState({
        idx: this.state.images.length - 1,
      });
    } else {
      this.setState({
        idx: this.state.idx - 1,
      });
    }
  };
  render() {
    if (this.state.multiple && this.state.images.length > 1) {
      return (
        <div>
          <div className=" text-center grid relative">
            <div className="w-full h-[444px] bg-product-bg grid content-center  ">
              <img
                src={this.state.images[this.state.idx]}
                className="object-contain h-[30%] place-self-center"
              />
            </div>
            <div className="flex pt-3 bg-product-bg justify-center w-full">
              <div className=" flex justify-between w-20 mb-6">
                <img
                  onClick={this.handlePrev}
                  className="hover:cursor-pointer"
                  src={left}
                ></img>
                <img
                  onClick={this.handleNext}
                  className="hover:cursor-pointer"
                  src={right}
                ></img>
              </div>
            </div>
          </div>
          <h1 className="pt-5 font-roboto text-product-text font-medium text-3xl w-full text-center">
            {this.props.id}
          </h1>
        </div>
      );
    } else {
      return (
        <div>
          <div className=" text-center grid relative">
            <div className="w-full h-[444px] bg-product-bg grid content-center ">
              <img
                src={this.state.images[this.state.idx]}
                className="object-contain h-[30%] place-self-center py-9p"
              />
            </div>

            <h1 className="pt-5 font-roboto text-product-text font-medium text-3xl w-full text-center">
              {this.props.id}
            </h1>
          </div>
        </div>
      );
    }
  }
}
