import React from 'react'
import homepagebg from "../img/homebagebg.jpg";


function Pageheader(props) {
    return (
        <div>
            <div className="w-full mt-14">
        <div className="relative">
          <img
            className="object-cover w-full h-48  object-top"
            src={homepagebg}
          ></img>
          <div className="absolute top-0 bg-gradient-to-t from-[#926653] w-full h-full  ">
            {""}
          </div>
          <div className="absolute bottom-0 h-full grid place-content-center ml-14">
            <h1 className="font-roboto font-bold text-5xl text-white">{props.title}</h1>
          </div>
        </div>
      </div>
            
        </div>
    )
}

export default Pageheader
