import React from 'react'
import { Link } from "react-router-dom";


function HeadingFooter(props) {
if(props.heading === true){
  return (
    <div>
      <h1 className='font-roboto font-bold text-lg text-white'>{props.name}</h1><br/>
    </div>
  )
}

else{
  return (
    <div>
      <div className='mb-2'>
      <Link to={props.link} className='font-poppins font-normal text-sm text-white'>{props.name}</Link>

      </div>
    </div>
  )

}
  
}



export default HeadingFooter
