import React from "react";
import Productstote from "./components/Productstote";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import bag1 from "./img/bags website photos/tote _ purse/1/1.1.png";
import bag1b from "./img/bags website photos/tote _ purse/1/1.2.png";
import bag2 from "./img/bags website photos/tote _ purse/2/2.2.png";
import bag2b from "./img/bags website photos/tote _ purse/2/2.1.png";
import bag3 from "./img/bags website photos/tote _ purse/3/3.2.png";
import bag3b from "./img/bags website photos/tote _ purse/3/3.1.png";
import bag4 from "./img/bags website photos/tote _ purse/4/4.2.png";
import bag4b from "./img/bags website photos/tote _ purse/4/4.1.png";
import bag4c from "./img/bags website photos/tote _ purse/4/4.3.png";
import bag5c from "./img/bags website photos/tote _ purse/5/5.3.png";
import bag5b from "./img/bags website photos/tote _ purse/5/5.1.png";
import bag5 from "./img/bags website photos/tote _ purse/5/5.2.png";
import bag5d from "./img/bags website photos/tote _ purse/5/5.4.png";
import bag6 from "./img/bags website photos/tote _ purse/6/6.2.png";
import bag6b from "./img/bags website photos/tote _ purse/6/6.1.png";


import bag7b from "./img/bags website photos/tote _ purse/7/7.2.png";



export default class Tote extends React.Component {
  state = {
    bags: [
      { id: "BHI-BAG-21-013", name: [bag1b,bag1] },
      { id: "BHI-BAG-21-014", name: [bag2,bag2b] },
      { id: "BHI-BAG-21-015", name: [bag3b,bag3] },
      { id: "BHI-BAG-21-017", name: [bag4,bag4b,bag4c] },
      { id: "BHI-BAG-21-016", name: [bag5,bag5b,bag5c,bag5d] },
      { id: "BHI-BAG-21-010", name: [bag6,bag6b] },
      { id: "BHI-BAG-21-019", name: [bag7b] },
    
    ],
  };

  render() {
    return (
      <div>
          <Navbar/>
         <Pageheader title="Tote Bags & Purses"/> 
        
        <div className=" grid grid-cols-2 gap-4 mt-4 mx-4 gap-y-4">

         
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Productstote images={item.name} id={item.id} />
            </div>
          ))}
          
        </div>

        

          

        <Footer/>
      </div>
    );
  }
}
