import React from "react";

import Product from "./components/Productknitted";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import belt1 from "./img/belts/photos/BETS STUDDED/1.png"
import belt2 from "./img/belts/photos/BETS STUDDED/2.png"
import belt3 from "./img/belts/photos/BETS STUDDED/3.png"
import belt4 from "./img/belts/photos/BETS STUDDED/4.png"
import belt5 from "./img/belts/photos/BETS STUDDED/5.png"
import belt6 from "./img/belts/photos/BETS STUDDED/6.png"
import belt7 from "./img/belts/photos/BETS STUDDED/7.png"
import belt8 from "./img/belts/photos/BETS STUDDED/8.png"
import belt9 from "./img/belts/photos/BETS STUDDED/9.png"
import belt10 from "./img/belts/photos/BETS STUDDED/10.png"

export default class Knittedbelts extends React.Component {
  state = {
    bags: [
      { id: "BHI-BELTS-21-054", name: [belt1] },
      { id: "BHI-BELTS-PS-2104", name: [belt2] },
      { id: "BHI-BELTS-PS-2109", name: [belt3] },
      { id: "BHI-BELTS-PS-2111", name: [belt4] },
      { id: "BHI-BELTS-21-055", name: [belt5] },
      { id: "BHI-BELTS-PS-2106", name: [belt6] },
      { id: "BHI-BELTS-PS-2113", name: [belt7] },
      { id: "BHI-BELTS-PS-2107", name: [belt8] },
      { id: "BHI-BELTS-PS-2112", name: [belt9] },
      { id: "BHI-BELTS-PS-2104", name: [belt10] },
      
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Formal Belts" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
