import React from "react";

function Block(props) {
  return (
    <div>
      <div className="group grayscale hover:grayscale-0 relative">
        <img src={props.img} className=" object-cover  w-[610px] h-[407px]" />
          <div className="absolute top-0 bg-gradient-to-t from-[#926653] w-full h-full z-0  ">
            {""}
          </div>
        <div className="absolute bottom-0 text-white grid place-items-center w-full h-full group-hover:opacity-100 opacity-0 z-10">
          <div>
            <h1 className="font-poppins uppercase font-semibold text-8xl ">
              {props.title}
            </h1>
            <h1 className="font-pt_serif capitalize font-normal text-5xl">
              {props.text}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block;
