import React from "react";

import Product from "./components/Productknitted";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import belt1 from "./img/belts/photos/BELTS FORMAL/1.png"
import belt2 from "./img/belts/photos/BELTS FORMAL/2.png"
import belt3 from "./img/belts/photos/BELTS FORMAL/3.png"
import belt4 from "./img/belts/photos/BELTS FORMAL/4.png"
import belt5 from "./img/belts/photos/BELTS FORMAL/5.png"
import belt6 from "./img/belts/photos/BELTS FORMAL/6.png"

export default class Formalbelts extends React.Component {
  state = {
    bags: [
      { id: "BHI-BELT-21-050", name: [belt1] },
      { id: "BHI-BELT-21-033 (BLACK)", name: [belt2] },
      { id: "BHI-BELT-21-033 (BROWN)", name: [belt3] },
      { id: "BHI-BELT-21-051", name: [belt4] },
      { id: "BHI-BELT-21-052", name: [belt5] },
      { id: "BHI-BELT-21-053", name: [belt6] },
      
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Formal Belts" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
