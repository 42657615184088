import React from 'react'

function Managestyle(props) {
    return (
        <div>
            <h1 className="text-white font-poppins font-medium text-5xl uppercase  ">{props.first} <br/> raja</h1>
              <h1 className="text-white font-poppins font-light text-2xl uppercase mt-3 ">{props.role} </h1>
        </div>
    )
}

export default Managestyle
