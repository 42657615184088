import React from "react";

import Product from "./components/Product";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import bag1 from "./img/bags website photos/bag packs/1/1.png";
import bag1b from "./img/bags website photos/bag packs/1/1.1.png";
import bag2 from "./img/bags website photos/bag packs/2/2.png";
import bag2b from "./img/bags website photos/bag packs/2/2.1.png";
import bag3 from "./img/bags website photos/bag packs/3/3.png";
import bag3b from "./img/bags website photos/bag packs/3/3.1.png";
import bag4c from "./img/bags website photos/bag packs/4/4.png";
import bag4b from "./img/bags website photos/bag packs/4/4.1.png";
import bag4 from "./img/bags website photos/bag packs/4/4.2.png";
import bag5c from "./img/bags website photos/bag packs/5/5.png";
import bag5b from "./img/bags website photos/bag packs/5/5.1.png";
import bag5 from "./img/bags website photos/bag packs/5/5.2.png";
import bag6b from "./img/bags website photos/bag packs/6/6.png";
import bag6 from "./img/bags website photos/bag packs/6/6.1.png";

import bag7c from "./img/bags website photos/bag packs/7/7.1.png";
import bag7b from "./img/bags website photos/bag packs/7/7.2.png";
import bag8c from "./img/bags website photos/bag packs/8/8.1.png";
import bag8b from "./img/bags website photos/bag packs/8/8.2.png";
import bag9b from "./img/bags website photos/bag packs/9/9.png";
import bag9 from "./img/bags website photos/bag packs/9/9.1.png";

export default class Backpacks extends React.Component {
  state = {
 
    bags: [
      { id: "BHI-BAG-21-020", name: [bag1, bag1b] },
      { id: "BHI-BAG-21-011", name: [bag2, bag2b] },
      { id: "BHI-BAG-21-012", name: [bag3, bag3b] },
      { id: "BHI-BAG-21-007", name: [bag4, bag4b, bag4c] },
      { id: "BHI-BAG-21-008", name: [bag5, bag5b, bag5c] },
      { id: "BHI-BAG-21-006", name: [bag6, bag6b] },
      { id: "BHI-BAG-21-009", name: [bag7b, bag7c] },
      { id: "BHI-BAG-21-005", name: [bag8b, bag8c] },
      { id: "BHI-BAG-21-018", name: [bag9, bag9b] },
    ],
  };

    
  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Backpacks" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product  images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
