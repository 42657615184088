import React from "react";

import Product from "./components/Productknitted";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import belt1 from "./img/belts/photos/FEMALE BELTS/1.png"
import belt2 from "./img/belts/photos/FEMALE BELTS/2.png"
import belt3 from "./img/belts/photos/FEMALE BELTS/3.png"
import belt4 from "./img/belts/photos/FEMALE BELTS/4.png"
import belt5 from "./img/belts/photos/FEMALE BELTS/5.png"
import belt6 from "./img/belts/photos/FEMALE BELTS/6.png"
import belt7 from "./img/belts/photos/FEMALE BELTS/7.png"


export default class Knittedbelts extends React.Component {
  state = {
    bags: [
      { id: "BHI-BELTS-21-018", name: [belt1] },
      { id: "BHI-BELTS-PS-2115", name: [belt2] },
      { id: "BHI-BELTS-PS-2117", name: [belt3] },
      { id: "BHI-BELTS-PS-2118", name: [belt4] },
      { id: "BHI-BELTS-PS-2116", name: [belt5] },
      { id: "BHI-BELTS-PS-2114", name: [belt6] },
      { id: "BHI-BELTS-PS-2119", name: [belt7] },
      
      
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Formal Belts" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
