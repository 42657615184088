import React from "react";
import Navbar from "./Navbar";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import obj1 from "./img/obj1.jpeg";
import obj2 from "./img/obj2.jpeg";
import obj3 from "./img/obj3.jpeg";
import obj4 from "./img/obj4.jpeg";
import Block from "./components/Block";

function Objective() {
  document.title = "BHI-Objective";
  const reachLink  = "https://echa.europa.eu/regulations/reach/understanding-reach#:~:text=REACH%20is%20a%20regulation%20of,of%20the%20EU%20chemicals%20industry."
  return (
    <div>
      <Navbar current="Objective" />
      <Pageheader title="Objective" />
      <div className="bg-sage-bg w-full h-[684px] text-center grid place-items-center">
        <div className="mx-64 grid place-items-center w-[938px]">
          <p className="font-pt_serif text-[#361B0F] font-bold text-5xl leading-[64px] ">
            Leading the way in the mass production of the leather sector and
            providing the best products to our customers and expanding our
            clientele.
          </p>
          <div className="w-[590px] h-1 bg-dashbrown my-7">{""}</div>
          <h1 className="text-[#361B0F] font-poppins text-2xl font-semibold uppercase">
            Blue Horizon International
          </h1>
        </div>
      </div>

      <div className="mx-20 my-14 ">
        <div className="grid grid-cols-2 gap-14 w-full place-items-center">
          {/* block */}

          <Block img={obj1} text="towards success" title="drive" />
          <Block img={obj2} text="the best quality" title="produce" />
          <Block img={obj3} text="the best results" title="offer" />
          <Block img={obj4} text="our clientele" title="delight" />
        </div>
      </div>

      <div className="bg-sage-bg w-full h-[500px] text-center grid place-items-center">
        <div className="mx-64 grid place-items-center w-[938px]">
          <p className="font-pt_serif text-[#361B0F] font-bold text-4xl leading-[48px] ">
            Our main priority is the quality that we deliver. We provide our
            customers with top-notch quality products, meeting the <a href={reachLink} target="_blank"  className="underline decoration-[#3683CA] decoration-[6px] underline-offset-4">REACH</a> norms
            of the European Union.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Objective;
