import React from "react";
import belt from "../img/beltsample.png";
import belt2 from "../img/beltsample2.png";
import right from "../img/right.svg";
import left from "../img/left.svg";

export default class Product extends React.Component {
  state = {
    
    images: this.props.images,
    idx: 0,
    multiple: true,
  };

  componentDidMount() {
    this.props.images.forEach((picture) => {
        const img = new Image();
        img.src = picture.fileName;
    });
}

  handleNext = () => {
    if (this.state.idx === this.state.images.length - 1) {
      this.setState({
        idx: 0,
      });
    } else {
      this.setState({
        idx: this.state.idx + 1,
      });
    }
  };

  handlePrev = () => {
    if (this.state.idx === 0) {
      this.setState({
        idx: this.state.images.length - 1,
      });
    } else {
      this.setState({
        idx: this.state.idx - 1,
      });
    }
  };

  // handleLoad = ()=>{
  //   document.querySelector(".image").style.visibility = 'visible';


  // };

  

  render() {
     if (this.state.multiple  && this.state.images.length>1 ) {
      return (
        <div >
          <div className=" text-center grid">
            <div className="w-full h-[444px] bg-product-bg grid content-center relative ">
              
              <img
                // onLoad={this.handleLoad}
                src={this.state.images[this.state.idx]}
                className="object-contain w-2/4 place-self-center image "
              >
               
              </img>
              <div className="flex absolute bottom-0 justify-center w-full">
                <div className=" flex justify-between w-20 mb-6 ">

                  <img onClick={this.handlePrev}  className="hover:cursor-pointer"  src={left}></img>
                  <img onClick={this.handleNext}   className="hover:cursor-pointer" src={right}></img>
                </div>
              </div>
            </div>
            <h1 className="pt-5 font-roboto text-product-text font-medium text-3xl">
              {this.props.id}
            </h1>
          </div>
        </div>
      );
    }
    else {
      return (
       
          <div className=" text-center grid">
            <div className="w-full h-[444px] bg-product-bg grid content-center relative ">
              <img
                src={this.state.images[this.state.idx]}
                className="object-contain w-2/4 place-self-center"
              />
              
            </div>
            <h1 className="pt-5 font-roboto text-product-text font-medium text-3xl">
              {this.props.id}
            </h1>
          </div>
        
      );
    }
  }
}
