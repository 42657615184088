import React from 'react'
import { Link } from "react-router-dom";

function Morebutton(props) {
    var prop_name = props.name;
    console.log('Prop name', prop_name)
    return (
        <div>
            <Link to={props.link}>
            <button className="rounded-full  text-white border-2 mx-7 border-white py-2 w-48 hover:text-navbar hover:bg-white text-sm font-poppins font-medium">
                    {props.name}
                  </button>
            
            </Link>
            
        </div>
    )
}

export default Morebutton
