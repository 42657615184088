import React from 'react'

function Textblock(props) {
    return (
        <div>

<div className="bg-sage-bg w-full h-[684px] relative grid   text-center flex-col">
            <div className="grid place-self-center ">
              <div className="italic font-poppins text-2xl font-medium leading-[36px]">
                <h1>{props.heading}</h1>
              </div>
              <div className="w-[97px] h-[9px] bg-dashbrown mb-10 mt-3 place-self-center">
                {" "}
              </div>
              <div className=" font-poppins font-normal  w-[762px] h-[342px] text-lg text-textcolor  ">
                {props.text}
              </div>
            </div>
          </div>
        </div>
            
    
    )
}

export default Textblock
