import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


function Portfoliotab(props) {
    return (
        <div>
            <Link to={props.link}>
          <div className=" w-full h-[436px] relative group bg-clip-content overflow-hidden ">
            <div className="transition duration-350 group-hover:scale-105 w-full h-full">
              <img src={props.img} className="object-cover h-full brightness-50 group-hover:brightness-75   "></img>
              <div className="absolute top-0 bg-gradient-to-t from-[#926653] w-full h-full"></div>
            </div>

            <div className="absolute top-0 grid content-center h-full w-full">
              <h1 className="font-poppins font-medium text-7xl text-white">
                {props.title}
              </h1>
            </div>
          </div>
        </Link>
            
        </div>
    )
}

export default Portfoliotab
