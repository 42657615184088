import React from "react";
import Pageheader from "./components/Pageheader";
import Product from "./components/Product";
import Navbar from "./Navbar";
import formal from "./img/formal belts/formalcover.png";
import semi from "./img/Semi formal belts/semicover.png";
import knitted from "./img/knitted belts/knitted cover.png";
import studded from "./img/studded belts/studded cover.png";
import female from "./img/female belts/female cover.png";
import { Link } from "react-router-dom";
import ProductContent from "./components/ProductContent";
import Footer from "./Footer";

function Belts() {
  return (
    <div>
      <Navbar />
      <Pageheader title="Belts" />
      <div className="flex">
        <div className=" w-1/2 my-4 mx-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50 ">
          <ProductContent cover={formal} title="Formal Belts" link="belts/formalbelts" />
        </div>
        <div className=" w-1/2 my-4 mr-4 bg-product-bg h-[594px] grid hover:bg-neutral-50">
          <ProductContent cover={semi} title="Semi Formal Belts" link="belts/semiformalbelts" />
        </div>
      </div>

      <div className="flex">
        <div className=" w-1/3  ml-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50">
          <ProductContent cover={knitted} title="Knitted Belts" link="belts/knittedbelts" />
        </div>

        <div className=" w-1/3  mx-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50">
          <ProductContent cover={studded} title="Studded Belts" link="belts/studdedbelts" />
        </div>
        <div className=" w-1/3  mr-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50">
          <ProductContent cover={female} title="Female Belts" link="belts/femalebelts" />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Belts;
