import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar";
import phone from "./img/phone.svg";
import location from "./img/location.svg";
import email from "./img/email.svg";
function ContactUs() {
  document.title = "BHI-Contact Us";
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "export_blue",
        "contact_form",
        form.current,
        "0WnLCFCE1v5kMN0bZ"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Successfully sent! ")
        },
        (error) => {
          console.log(error.text);
          alert("There was an error please try again")
        }
      );
  };
  return (
    <div>
      <Navbar current="Contact Us" />
      <Pageheader title="Contact Us" />
      <div className="w-full bg-sage-bg h-[874px]">
        <div className="flex w-full px-36 pt-16 ">
          <div className="basis-3/4 mr-24">
            <h1 className="font-poppins text-6xl font-semibold text-dashbrown capitalize">
              Send us a message
            </h1>
            <p className="font-poppins text-lg text-dashbrown mt-3 mb-10">
              Feel free to contact us anytime. We will get back to you as soon
              as we can!
            </p>
            {/* fields */}

            <form ref={form} onSubmit={sendEmail}>
              <label className="font-poppins text-2xl text-dashbrown font-medium mb-3 after:content-['*'] after:text-red-500 after:ml-0.5">
                Name
              </label>

              <input
                type="text"
                name="user_name"
                className="h-16 w-[670px] font-poppins font-medium rounded-md px-11 placeholder:font-poppins focus:outline-dashbrown mb-8 "
                placeholder="Full Name"
              ></input>

              <label className="font-poppins text-2xl text-dashbrown font-medium mb-3 after:content-['*'] after:text-red-500 after:ml-0.5">
                Email
              </label>
              <input
                type="email"
                name="user_email"
                className="h-16 w-[670px] font-poppins font-medium rounded-md px-11 placeholder:font-poppins focus:outline-dashbrown mb-8 "
                placeholder="someone@example.com"
              ></input>

              <label className="font-poppins text-2xl text-dashbrown font-medium mb-3 after:content-['*'] after:text-red-500 after:ml-0.5">
                Message
              </label>
              <textarea
              name="message"
                className="h-16 w-[670px] font-poppins font-medium rounded-md mt-2 px-11 py-5 placeholder:font-poppins focus:outline-dashbrown mb-8 "
                placeholder="Your Message"
              ></textarea>

              <br />
              <input
                type="submit"
                value="Send"
                className="cursor-pointer	  w-[670px] h-16 hover:bg-opacity-95 bg-dashbrown text-center capitalize text-white hover:outline-white rounded-md text-2xl font-poppins font-normal"
              >
                
              </input>
            </form>
          </div>

          {/* other info */}
          <div className="pt-24">
            <h1 className="font-poppins text-6xl font-semibold text-dashbrown mb-14 text-center">
              Other Info
            </h1>

            <div className=" ">
              <div className="flex mt-14">
                <img src={location} className="mr-11"></img>
                <p className="font-poppins text-dashbrown font-normal text-lg ">
                  Registered Office Address: 7/81 Narayan Apartment, Tilak
                  Nagar, 4th Floor, Tf-1, Kanpur - 208002, India <br /> <br />
                  Factory Address: 16, Akarampur Industrial Estate, Akarampur,
                  Near T V Tower, UNNAO - 209862 (UP) , INDIA 
                </p>
              </div>
              <div className="flex my-20">
                <img src={email} className="mr-11"></img>
                <p className="font-poppins text-dashbrown font-normal text-lg ">
                  export@bluehorizon.international
                </p>
              </div>
              <div className="flex mb-40">
                <img src={phone} className="mr-11"></img>
                <p className="font-poppins text-dashbrown font-normal text-lg ">
                  Business: +91 9839081558 <br /> WhatsApp: +91 9580506450
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
