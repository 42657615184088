import React from "react";
import Pageheader from "./components/Pageheader";
import Product from "./components/Product";
import Navbar from "./Navbar";
import formal from "./img/formal belts/formalcover.png";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import bags from "./img/pbags.jpg";
import wallets from "./img/pwallets.jpeg";
import belts from "./img/pbelts.jpeg";
import Portfoliotab from "./components/Portfoliotab";

function ProductPortfolio() {
  document.title = "BHI-Product Portfolio";
  return (
    <div>
      <Navbar current="Product Portfolio" />
      <Pageheader title="Product Porfolio" />

      <div className="grid grid-cols-3 gap-8 mt-16 mx-12 text-center ">
        <Portfoliotab title="Bags" img={bags} link="productportfolio/bags"/>
        <Portfoliotab title="Belts" img={belts} link="productportfolio/belts"/>
        <Portfoliotab title="Wallets" img={wallets} link="/productportfolio/wallets"/>
        
      </div>
      <Footer />
    </div>
  );
}

export default ProductPortfolio;
