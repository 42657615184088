import React from "react";
import Navbar from "./Navbar";
import Textblock from "./components/Textblock";
import Footer from "./Footer";
import design from "./img/design.jpg";
import leather from "./img/leather.jpeg";
import our from "./img/our.png";
import Slider from "./components/Slider.js";
import Pageheader from "./components/Pageheader";
import globe from "./img/globe.svg"
import work from "./img/work.svg"
import service from "./img/service.png"
import Clientele from "./components/Clientele";


function AboutUs() {
  document.title = "BHI-About Us";

  const textDesign =
    "Our design team is led by one of the directors of the company, who is a design graduate form Aston University, United Kingdom. His expertise helps us to cater to the needs of our customers according to their requirements.";
  const textLeather =
    "Our organization consists of a technical team of top leather technicians trained in the laboratories of the best Italian chemical manufacturers for the development of leather.";

  const textOur =
    "Various kinds of leathers are used depending on the requirements of our customers, including South American cow leather, Indian buff leather, and Indian sheep leather, and several others. ";

  return (
    <div>
      <Navbar current="About Us" />

      <Pageheader title="About Us" />

      <Textblock
        heading="Who We Are"
        text={
          <p>
            Blue Horizon International is a North Indian, family-owned company
            that began working as a producer of leather goods and accessories in
            2014.
            <br />
            <br />
            But our story begins in 1992, from manufacturing and trading leather
            tannery chemicals and later using our industry experience, we dived
            into the industry of producing leather accessories.
            <br />
            <br /> Our company comes under the list of one of the few producers
            in India for handcrafted leather belts.
          </p>
        }
      ></Textblock>

      <Slider
        images={[design, leather, our]}
        title={["Design And R&D", "Leather Development", "Our Leather"]}
        text={[textDesign, textLeather, textOur]}
      ></Slider>

      {/* icons */}

      <div className="bg-sage-bg w-full h-[576px] mt-11 ">
        <div className="grid place-content-center  h-full">
          {/* 3 divs */}
          <div className="flex w-[1250px] justify-between text-center">

       
          <Clientele image={globe} text={"The company has a European clientele, which includes countries Italy, Finland, and Greece."}/>
          <Clientele image={work} text={"We aim to cater to the requirements of our clients with the help of our design and technical team."}/>
          <Clientele image={service} text={"Our company’s main motto is client satisfaction and we firmly believe in offering out the best services in the industry"}/>
          
          
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
