import React from "react";
import Productsduffel from "./components/Productsduffel";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import bag1b from "./img/bags website photos/duffel bags _ laptop bags/1/1.1.png";
import bag1 from "./img/bags website photos/duffel bags _ laptop bags/1/1.2.png";
import bag2 from "./img/bags website photos/duffel bags _ laptop bags/2/2.1.png";
import bag2b from "./img/bags website photos/duffel bags _ laptop bags/2/2.2.png";
import bag3c from "./img/bags website photos/duffel bags _ laptop bags/3/3.1.png";
import bag3b from "./img/bags website photos/duffel bags _ laptop bags/3/3.2.png";
import bag3 from "./img/bags website photos/duffel bags _ laptop bags/3/3.3.png";
import bag4c from "./img/bags website photos/duffel bags _ laptop bags/4/4.1.png";
import bag4b from "./img/bags website photos/duffel bags _ laptop bags/4/4.2.png";
import bag4d from "./img/bags website photos/duffel bags _ laptop bags/4/4.3.png";
import bag4a from "./img/bags website photos/duffel bags _ laptop bags/4/4.4.png";





export default class Duffel extends React.Component {
  state = {
    bags: [
      { id: "BHI-BAG-21-021", name: [bag1,bag1b] },
      { id: "BHI-BAG-21-022", name: [bag2,bag2b] },
      { id: "BHI-BAG-21-024", name: [bag3,bag3b,bag3c] },
      { id: "BHI-BAG-21-023", name: [bag4a,bag4b,bag4c,bag4d] },
    ],
  };

  render() {
    return (
      <div>
          <Navbar/>
         <Pageheader title="Duffel Bags"/> 
        
        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">

         
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Productsduffel images={item.name} id={item.id} />
            </div>
          ))}
          
        </div>

        

          

        <Footer/>
      </div>
    );
  }
}
