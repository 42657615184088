import React from "react";

import Product from "./components/Productknitted";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";


import belt1 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/1.png"
import belt2 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/2.png"
import belt3 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/3.png"
import belt4 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/4.png"
import belt5 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/5.png"
import belt6 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/6.png"
import belt7 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/7.png"
import belt8 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/8.png"
import belt9 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/9.png"
import belt10 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/10.png"
import belt11 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/11.png"
import belt12 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/12.png"
import belt13 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/13.png"
import belt14 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/14.png"
import belt15 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/15.png"
import belt16 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/16.png"
import belt17 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/17.png"
import belt18 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/18.png"
import belt19 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/19.png"
import belt20 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/20.png"
import belt21 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/21.png"
import belt22 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/22.png"
import belt23 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/23.png"
import belt24 from "./img/belts/photos/BELTS GRAIN SEMI FORMAL/24.png"

export default class Semiformalbelts extends React.Component {
  state = {
    bags: [
      { id: "BHI-BELT-21-029", name: [belt1] },
      { id: "BHI-BELT-21-002", name: [belt2] },
      { id: "BHI-BELT-21-027", name: [belt3] },
      { id: "BHI-BELT-21-026", name: [belt4] },
      { id: "BHI-BELT-21-006", name: [belt5] },
      { id: "BHI-BELT-21-041", name: [belt6] },
      { id: "BHI-BELT-21-009", name: [belt7] },
      { id: "BHI-BELT-21-037", name: [belt8] },
      { id: "BHI-BELT-21-042", name: [belt9] },
      { id: "BHI-BELT-21-012", name: [belt10] },
      { id: "BHI-BELT-21-043", name: [belt11] },
      { id: "BHI-BELT-21-044", name: [belt12] },
      { id: "BHI-BELT-21-045", name: [belt13] },
      { id: "BHI-BELT-21-046", name: [belt14] },
      { id: "BHI-BELT-21-047", name: [belt15] },
      { id: "BHI-BELT-21-048", name: [belt16] },
      { id: "BHI-BELT-21-016", name: [belt17] },
      { id: "BHI-BELT-21-049", name: [belt18] },
      { id: "BHI-BELT-21-019", name: [belt19] },
      { id: "BHI-BELT-21-020", name: [belt20] },
      { id: "BHI-BELT-21-021", name: [belt21] },
      { id: "BHI-BELT-21-022", name: [belt22] },
      { id: "BHI-BELT-21-023", name: [belt23] },
      { id: "BHI-BELT-21-024", name: [belt24] },
      
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Formal Belts" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
