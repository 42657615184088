import React from "react";

import Product from "./components/Productknitted";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import belt1 from "./img/belts/photos/BELTS KNITTED/1.png"
import belt2 from "./img/belts/photos/BELTS KNITTED/2.png"
import belt3 from "./img/belts/photos/BELTS KNITTED/3.png"
import belt4 from "./img/belts/photos/BELTS KNITTED/4.png"
import belt5 from "./img/belts/photos/BELTS KNITTED/5.png"
import belt6 from "./img/belts/photos/BELTS KNITTED/6.png"

export default class Knittedbelts extends React.Component {
  state = {
    bags: [
      { id: "BHI-BELTS-KN-2101", name: [belt1] },
      { id: "BHI-BELTS-KN-2102", name: [belt2] },
      { id: "BHI-BELTS-KN-2103", name: [belt3] },
      { id: "BHI-BELTS-KN-2104", name: [belt4] },
      { id: "BHI-BELTS-KN-2105", name: [belt5] },
      { id: "BHI-BELTS-KN-2106", name: [belt6] },
      
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Formal Belts" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
