import React from "react";
import Navbar from "./Navbar";

function OurManagement() {
  return (
    <div>
      <Navbar current="Our Management" />
    </div>
  );
}

export default OurManagement;
