import "./App.css";
import {isMobile} from 'react-device-detect';
import Navbar from "./Navbar";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import ContactUs from "./ContactUs";
import Objective from "./Objective";
import AboutUs from "./AboutUs";
import ProductPortfolio from "./ProductPortfolio";
import OurManagement from "./OurManagement";
import React from "react";
import { tab } from "@testing-library/user-event/dist/tab";
import Belts from "./Belts";
import Bags from "./Bags";
import Backpacks from "./Backpacks";
import Duffel from "./Duffel.js";
import Tote from "./Tote.js";
import Waist from "./Waist";
import Formalbelts from "./Formalbelts";
import Semiformalbelts from "./Semiformalbelts";
import Studdedbelts from "./Studdedbelts";
import Knittedbelts from "./Knittedbelts";
import Femalebelts from "./Femalebelts";
import Wallets from "./Wallets";
import ScrollToTop from "./ScrollToTop";
import logo from "./img/logo.png";
export default class App extends React.Component {

  
  render() {

    if(isMobile){
      return(
        <>
        <div className=" grid place-content-center bg-sage-bg h-screen text-center text-xl font-poppins  text-navbar px-7 capitalize">
          <img className="object-contain w-2/6 flex justify-self-center mb-8" src={logo}></img>
          <h1 className="flex justify-center font-bold ">Hi! <br/>This website is only available on desktop</h1>

           <h2 className="text-lg mt-6">You can still reach out to us!</h2>

          
           <a  className="bg-navbar rounded-lg text-center mt-3 text-sage-bg text-lg" href="tel:+919839081558" > call Us</a>
          
           {/* <a  className="bg-dashbrown rounded-lg text-center text-sage-bg text-lg mt-3" href="" > Call Us</a> */}
           <a  className="bg-navbar rounded-lg text-center mt-3 text-sage-bg text-lg" href="mailto:export@bluehorizon.international" > Email Us</a>
           {/* <a  className="bg-dashbrown rounded-lg text-center text-sage-bg text-lg mt-3" href="mailto:export@bluehorizon.international" > email Us</a> */}

                   
          <p className="capitalize text-base font-thin mt-3">Thanks for visiting Blue Horizon</p>

        </div>
      </>
      );
    }else{
    return (
      <>
      <ScrollToTop>


        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/AboutUs">
            <AboutUs />
          </Route>
          <Route exact path="/contactus">
            <ContactUs />
          </Route>
          <Route exact path="/objective">
            <Objective />
          </Route>
          <Route exact path="/productportfolio">
            <ProductPortfolio />
          </Route>
          <Route exact path="/ourmanagement">
            <OurManagement />
          </Route>

          <Route exact path="/ProductPortfolio/Belts">
            <Belts />
          </Route>
          <Route exact path="/ProductPortfolio/Bags">
            <Bags />
          </Route>
          <Route exact path="/ProductPortfolio/Bags/Backpacks">
            <Backpacks />
          </Route>
          <Route exact path="/ProductPortfolio/Bags/Duffelbags">
            <Duffel />
          </Route>
          <Route exact path="/ProductPortfolio/Bags/Tote&Purses">
            <Tote />
          </Route>
          <Route exact path="/ProductPortfolio/Bags/Waistbags">
            <Waist />
          </Route>
          <Route exact path="/ProductPortfolio/belts/formalbelts">
            <Formalbelts />
          </Route>
          <Route exact path="/ProductPortfolio/belts/semiformalbelts">
            <Semiformalbelts/>
          </Route>
          <Route exact path="/ProductPortfolio/belts/studdedbelts">
            <Studdedbelts/>
          </Route>
          <Route exact path="/ProductPortfolio/belts/knittedbelts">
            <Knittedbelts/>
          </Route>
          <Route exact path="/ProductPortfolio/belts/femalebelts">
            <Femalebelts/>
          </Route>
          <Route exact path="/ProductPortfolio/wallets">
            <Wallets/>
          </Route>
        </Switch>
      </ScrollToTop>
      </>
    );
  }
}
}
