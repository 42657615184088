import React, { useState } from "react";
import logo from "./img/logo_white.png";
import Tabs from "./navbarComponents/Tabs";
import Search from "./navbarComponents/Search";

function Nav(props) {
  return (
    
    <div className=" fixed top-0 right-0 left-0 mb-16 w-full flex items-center justify-between bg-navbar h-20 z-50">
      <div className="flex font-play font-medium   justify-start ml-16 ">
        <img src={logo} className="object-contain w-16"></img>
        
      </div>

      <div className="flex items-center justify-end text-base -sm w-full">
        <Tabs name="Home" current={props.current} link="/" />
        <Tabs name="About Us" current={props.current} link="/AboutUs" />
        
        <Tabs
          name="Product Portfolio"
          current={props.current}
          link="/ProductPortfolio"
        />
        
        <Tabs name="Objective" current={props.current} link="/Objective" />
        <Tabs name="Contact Us" current={props.current} link="/ContactUs" />

        <Search />
      </div>
    </div>
  );
}

export default Nav;
