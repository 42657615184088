import { render } from '@testing-library/react';
import React from 'react'
import { CSSTransition, SwitchTransition } from "react-transition-group";
import left from "../img/leftslide.svg";
import right from "../img/rightslide.svg";

export default class Slider extends React.Component {

    state={
        images: this.props.images,
        idx: 0,
        title: this.props.title,
        text: this.props.text,
  
      };
  
        handleNext = () => {
      if (this.state.idx === this.state.images.length - 1) {
        this.setState({
          idx: 0,
          
        });
        console.log(this.state.inProp);
      } else {
        this.setState({
          idx: this.state.idx + 1,
          
        });
        console.log(this.state.inProp);
      }
    };
  
    handlePrev = () => {
      if (this.state.idx === 0) {
        this.setState({
          idx: this.state.images.length - 1,
        });
      } else {
        this.setState({
          idx: this.state.idx - 1,
        });
      }
    };

    render(){


        return (
            <div>
    
                {/************************* IMAGE SLIDER ***********************/}
    
            {/* image slider -> image *************************************/}
            <div >
              <SwitchTransition>
                <CSSTransition
                  key={this.state.idx}
                  in={true}
                  timeout={300}
                  classNames="fade"
                >
                  <div className=" flex w-full justify-center relative">
                    <img
                      src={this.state.images[this.state.idx]}
                      className="w-full object-cover h-[664px] object-top"
                    ></img>
    
                    <div className="absolute  top-0 bg-gradient-to-t from-[#592109] w-full h-full">
                      {""}
                    </div>

                    <div className="absolute top-0 h-full grid place-content-center">

                    <h1 className='font-poppins text-white text-center text-6xl italic font-bold' >{this.state.title[this.state.idx]}</h1>
                    <h1 className='font-poppins text-white text-lg text-center font-normal w-[777px] mt-3' >{this.state.text[this.state.idx]}</h1>

                    </div>

                    



                  </div>

                  
                </CSSTransition>
              </SwitchTransition>
              {/* image slider -> image controls ************************/}
              <div className="w-full flex justify-center mt-7 items-center">
                {/* image slider -> image controls-> left side */}
                <img
                  src={left}
                  className="ml-3 hover:cursor-pointer"
                  onClick={this.handlePrev}
                />
                {/* image slider -> image controls-> middle section********/}
                <div className="h-[42px] w-14">
                  <h1 className=" bg-[#1E1E1E] opacity-80 text-slate-50 font-poppins font-bold h-full flex justify-center items-center">
                    {this.state.idx + 1} / {this.state.images.length}
                  </h1>
                </div>
                {/* image slider -> image controls **********************/}
                <img
                  src={right}
                  className="mr-3 hover:cursor-pointer"
                  onClick={this.handleNext}
                />
              </div>
            </div>
            {/********** Image Slider End********/}
                
            </div>
        );
    }
    
}
