import React from 'react'

function Clientele(props) {
    return (
        <div>
             <div className="w-80  grid place-items-center font-poppins text-lg font-normal ">
            <img src={props.image} className="mb-9"></img>
            <p>{props.text}</p>
          </div>
          <div></div>
            
        </div>
    )
}

export default Clientele
