import React from "react";

import Product from "./components/Product";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import bag1b from "./img/WALLETS/1/1.1.png";
import bag1 from "./img/WALLETS/1/1.2.png";
import bag2 from "./img/WALLETS/2/2.1.png";
import bag2b from "./img/WALLETS/2/2.2.png";
import bag3b from "./img/WALLETS/3/3.1.png";
import bag3 from "./img/WALLETS/3/3.2.png";
import bag4 from "./img/WALLETS/4/4.1.png";
import bag4b from "./img/WALLETS/4/4.2.png";

import bag5 from "./img/WALLETS/5/5.1.png";
import bag5b from "./img/WALLETS/5/5.2.png";

import bag6b from "./img/WALLETS/6/6.1.png";
import bag6 from "./img/WALLETS/6/6.2.png";

import bag7b from "./img/WALLETS/7/7.1.png";
import bag7 from "./img/WALLETS/7/7.2.png";
import bag8b from "./img/WALLETS/8/8.1.png";
import bag8 from "./img/WALLETS/8/8.2.png";
import bag9b from "./img/WALLETS/9/9.2.png";
import bag9 from "./img/WALLETS/9/9.1.png";
import bag10 from "./img/WALLETS/10/10.2.png";
import bag10b from "./img/WALLETS/10/10.1.png";
import bag11 from "./img/WALLETS/11/11.2.png";
import bag11b from "./img/WALLETS/11/11.1.png";

export default class Wallets extends React.Component {
  state = {
    bags: [
      { id: "BHI-WLT-21-001", name: [bag1, bag1b] },
      { id: "BHI-WLT-21-002", name: [bag2, bag2b] },
      { id: "BHI-WLT-21-003", name: [bag3, bag3b] },
      { id: "BHI-WLT-21-004", name: [bag4, bag4b,] },
      { id: "BHI-WLT-21-005", name: [bag5, bag5b,] },
      { id: "BHI-WLT-21-006", name: [bag6, bag6b] },
      { id: "BHI-WLT-21-007", name: [bag7, bag7b] },
      { id: "BHI-WLT-21-008", name: [bag8, bag8b] },
      { id: "BHI-WLT-21-009", name: [bag9, bag9b] },
      { id: "BHI-WLT-21-010", name: [bag10, bag10b] },
      { id: "BHI-WLT-21-011", name: [bag11, bag11b] },
    ],
  };

  render() {
    return (
      <div>
        <Navbar />
        <Pageheader title="Wallets" />

        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Product images={item.name} id={item.id} />
            </div>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}
