import React from "react";
import Productswaist from "./components/Productswaist";
import Pageheader from "./components/Pageheader";
import Footer from "./Footer";
import Navbar from "./Navbar.js";

import bag1 from "./img/bags website photos/waist bags/1/1.1.png";
import bag1b from "./img/bags website photos/waist bags/1/1.2.png";
import bag2 from "./img/bags website photos/waist bags/2/2.2.png";
import bag2b from "./img/bags website photos/waist bags/2/2.1.png";
import bag3 from "./img/bags website photos/waist bags/3/3.2.png";
import bag3b from "./img/bags website photos/waist bags/3/3.1.png";






export default class Waist extends React.Component {
  state = {
    bags: [
      { id: "BHI-BAG-21-001", name: [bag1,bag1b] },
      { id: "BHI-BAG-21-002", name: [bag2b,bag2] },
      { id: "BHI-BAG-21-003", name: [bag3,bag3b] },
   
    
    ],
  };

  render() {
    return (
      <div>
          <Navbar/>
         <Pageheader title="Waist Bags"/> 
        
        <div className=" grid grid-cols-3 gap-4 mt-4 mx-4 gap-y-4">

         
          {this.state.bags.map((item) => (
            <div key={item.id}>
              <Productswaist images={item.name} id={item.id} />
            </div>
          ))}
          
        </div>

        

          

        <Footer/>
      </div>
    );
  }
}
