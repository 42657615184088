import React from 'react'


function Search(props) {
    return (
        <div className="font-roboto justify-end mr-16 px-5 text-slate-100 ">
            <input type="text" className='  rounded-lg bg-stone-900 h-12 text-slate-100 px-8 placeholder-searchbg focus:shadow-outline focus:outline-none focus:border-current focus:ring-1 focus:ring-current'  placeholder="Search Here"/>
            </div>
    
    )
}


export default Search




