import React from "react";
import HeadingFooter from "./components/HeadingFooter.js";
import logo from "./img/logo_white.png";
import insta from './img/instagram.svg';
import fb from "./img/facebook.svg";
import email from "./img/mail.svg";

function Footer() {
  return (
    <div>
      <div className="w-full bg-navbar  grid content-center mt-20">
        {/*Main Div  */}

        <div className="flex justify-between mx-40 text-white mt-[70px] mb-20">
          {/* Customer Service */}
          
          {/* Contact Us  */}
          <div>
            
            <HeadingFooter name="CONTACT US" heading={true} />
            <HeadingFooter name="Phone: +91 9580506450" link="/"/>
            <HeadingFooter name="Email: export@bluehorizon.international" link="/"/>

            
          </div>
          {/* About Us */}
          <div>
          <HeadingFooter name="ABOUT US" heading={true} />
            <HeadingFooter name="Who we are" link="/AboutUs"/>
            <HeadingFooter name="Our Team" link="/AboutUs"/>
            
          </div>
          {/* Logo */}
          <div className="ml-52 text-right  ">
            <div className="flex justify-end">

              <img src={logo} className="object-contain max-h-24 mb-2 "></img>
            </div>

              <h2>© BHI 2022 All Rights Reserved</h2>
              <div className="flex justify-end space-x-3 mt-2">
                
                <img src={email}></img>
                <img src={fb}></img>
                <img src={insta}></img>
              </div>
              
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;



