import React from "react";
import { Link } from "react-router-dom";

function ProductContent(props) {
  return (
    <div>
      <Link to={props.link}>
        <div className="grid content-center w-full h-full text-center">
          <img
            src={props.cover}
            className="w-7/12 place-self-center object-contain"
          ></img>
          <h1 className="font-roboto font-light text-4xl mt-11">
            {props.title}
          </h1>
          <h1 className="font-roboto font-extralight mt-3 text-xl ">
            Show More
          </h1>
        </div>
      </Link>
    </div>
  );
}

export default ProductContent;
