import React from "react";
import { Link } from "react-router-dom";

export default class Tabs extends React.Component {
  render() {
    if (this.props.name === this.props.current) {
      return (
        <Link
          to={this.props.link}
          className="flex font-poppins font-normal  mx-4  text-slate-100 hover:animate-pulse underline decoration-4 hover:decoration-solid decoration-current underline-offset-4 "
        >
          {this.props.name}
        </Link>
      );
    } else {
      return (
        <Link
          to={this.props.link}
          className="flex font-poppins font-normal  justify-end mx-4  text-slate-100  hover:animate-pulse"
        >
          {this.props.name}
        </Link>
      );
    }
  }
}
