import React from "react";
import Navbar from "./Navbar";
import homepagebg from "./img/homebagebg.jpg";
import arrow from "./img/arrow.svg";
// import img1 from "./img/slider1.jpg";
import img2 from "./img/slider2.jpg";
import left from "./img/leftslide.svg";
import right from "./img/rightslide.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import Morebutton from "./components/Morebutton";
import Duffel from "./img/duffel.png";
import Wallet from "./img/wallet.png";
import Backpack from "./img/backpack.png";
import Belt from "./img/belt.png";
import Beltblack from "./img/blackbelt.png";
import logo from "./img/logo.png"
import Footer from "./Footer.js";
import Textblock from "./components/Textblock";
import Managestyle from "./components/Managestyle";
export default class Home extends React.Component {
  state = {
    images: [img2, img2, img2, img2],
    idx: 0,
  };

  handleNext = () => {
    if (this.state.idx === this.state.images.length - 1) {
      this.setState({
        idx: 0,
      });
      console.log(this.state.inProp);
    } else {
      this.setState({
        idx: this.state.idx + 1,
      });
      console.log(this.state.inProp);
    }
  };

  handlePrev = () => {
    if (this.state.idx === 0) {
      this.setState({
        idx: this.state.images.length - 1,
      });
    } else {
      this.setState({
        idx: this.state.idx - 1,
      });
    }
  };

  showCurrent = () => {
    return this.state.idx + 1;
  };

  render() {
    document.title = "BHI-Home";
    return (
      <div>
        <div>
          <div className="fixed z-50">
            <Navbar current="Home" />
          </div>

          <div className="flex-col justify-items-center relative mt-14 z-0">
            <img className="w-full" src={homepagebg}></img>
            <div className="absolute top-0 bg-gradient-to-t from-[#926653] w-full h-full">
              {" "}
            </div>
            <div className="absolute bottom-0 w-full grid justify-items-stretch z-10">
              <img className="my-16 justify-self-center " src={arrow}></img>
            </div>
            <div className="absolute bottom-0 content-center h-full w-full grid justify-items-stretch ">
              <div className=" w-full grid justify-items-center ">
                <div className="text-left">
                  <h1 className="text-gray-50 font-poppins font-medium text-7xl  ">
                    Blue Horizon International
                  </h1>
                  <h1 className="text-gray-50 font-poppins font-normal text-3xl mt-2 ">
                    Find great handcrafted leather goods
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <Textblock
            heading="Our Story"
            text={
              <p>
                Blue Horizon International is an organization engaged in
                producing, delivering, and exporting leather products going from
                sacks, bags, belts, wallets, and accessories.
                <br />
                <br />
                It is situated in the Manchester of India or as the city is
                called Kanpur in the province of Uttar Pradesh, India, it is
                presently one of the best leather centres not only in the
                country but also in the world.
                <br />
                <br /> We, as an organization, always try to offer the best
                quality, maintain consistency, and dispatch the product on time
                as we are eager to cater with excellent service to our customers
                every time.
              </p>
            }
          ></Textblock>
        </div>


        <div>
          <h1 className="text-dashbrown font-medium font-poppins text-6xl flex justify-center mt-16">
            {" "}
            Products{" "}
          </h1>

          <div className="w-full flex justify-center h-full mt-9">
            <div className="w-[1250px]   ">
              <div className="flex justify-center mb-6">
                <div className="w-[250px] h-[250px] bg-sage-bg flex   mr-4">
                  <div className=" flex justify-center ">
                    <img
                      className="object-scale-down scale-75 flex "
                      src={Belt}
                    ></img>
                  </div>
                </div>
                <div className="w-[250px] h-[250px] bg-sage-bg flex justify-end  mx-4">
                  <div className=" flex justify-center ">
                    <img
                      className="object-scale-down scale-50 flex "
                      src={Wallet}
                    ></img>
                  </div>
                </div>
                <div className="w-[250px] h-[250px] bg-sage-bg flex justify-end   mx-4">
                  <div className=" flex justify-center ">
                    <img
                      className="object-scale-down scale-50 flex "
                      src={Backpack}
                    ></img>
                  </div>
                </div>
                <div className="w-[250px] h-[250px] bg-sage-bg flex justify-end   mx-4">
                  <div className=" flex justify-center ">
                    <img
                      className="object-scale-down scale-75 flex "
                      src={Duffel}
                    ></img>
                  </div>
                </div>
                <div className="w-[250px] h-[250px] bg-sage-bg flex justify-end   ml-4">
                  <div className=" flex justify-center ">
                    <img
                      className="object-scale-down scale-75 flex "
                      src={Beltblack}
                    ></img>
                  </div>
                </div>
              </div>

              {/* black section */}
              <div className="bg-navbar w-full h-[180px]">
                {""}

                <div className="text-center text-white font-poppins">
                  <h1 className="font-semibold text-4xl pt-5">
                    Like what you see?
                  </h1>
                  <h1 className="text-lg">Check out more products</h1>
                </div>

                <div className="flex justify-center mt-5">
                  <Morebutton
                    name="BELTS"
                    link="/Productportfolio/belts"
                  ></Morebutton>
                  <Morebutton
                    name="WALLETS"
                    link="/Productportfolio/wallets"
                  ></Morebutton>
                  <Morebutton
                    name="BAGS"
                    link="/Productportfolio/bags"
                  ></Morebutton>
                  <Morebutton
                    name="DUFFEL BAGS"
                    link="/Productportfolio/bags/duffelbags"
                  ></Morebutton>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <Footer />
      </div>
    );
  }
}
