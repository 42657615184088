import React from "react";
import Pageheader from "./components/Pageheader";
import Navbar from "./Navbar.js";
import backpack from "./img/backpacks/backpack cover.png"
import duffel from "./img/duffel bags/duffel cover.png"
import tote from "./img/tote bags/tote cover.png"
import waist from "./img/waist bags/waist cover.png"
import ProductContent from "./components/ProductContent";
import { Link } from "react-router-dom";
import Footer from "./Footer";
function Bags() {
  return (
    <div>
      <Navbar />
      <Pageheader title="Bags" />
      <div className="flex">
        <div className=" w-1/2 my-4 mx-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50 ">
        <Link to="bags/backpacks">
        <div className="grid content-center w-full h-full text-center">
          <img
            src={backpack}
            className="w-5/12 place-self-center object-contain"
          ></img>
          <h1 className="font-roboto font-light text-4xl mt-10">
            Backpacks
          </h1>
          <h1 className="font-roboto font-extralight mt-3 text-xl ">
            Show More
          </h1>
        </div>
      </Link>
        </div>
        <div className=" w-1/2 my-4 mr-4 bg-product-bg h-[594px] grid hover:bg-neutral-50">
          <ProductContent cover={duffel} title="Duffel & Laptop Bags" link="bags/duffelbags" />
        </div>
      </div>
      <div className="flex">
        <div className=" w-1/2  mx-4  bg-product-bg h-[594px] grid  hover:bg-neutral-50 ">
          <ProductContent cover={tote} title="Tote Bags & Purses" link="bags/tote&purses" />
        </div>
        <div className=" w-1/2 mr-4 bg-product-bg h-[594px] grid hover:bg-neutral-50">
          <ProductContent cover={waist} title="Waist Bags" link="bags/waistbags" />
        </div>
      </div>

    <Footer/>


    </div>
  );
}

export default Bags;
